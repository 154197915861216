// src/components/About.js
import React from 'react';
import { Box, Typography, Container, Grid, Avatar, Paper, Link as MuiLink, Button } from '@mui/material';
import { Link } from 'react-router-dom';
import './About.css'; // Import component-specific styles


import feature7 from '../assets/images/mvg/1.png';
import feature8 from '../assets/images/mvg/6.png';
import feature9 from '../assets/images/mvg/8.png';



import featuredmap from '../assets/images/map2.png';


function About() {
  return (
    <Box className="about-container">
      {/* Hero Section */}
      <Box className="about-hero-section">
        <Container maxWidth="md">
          <Typography className="about-hero-title" gutterBottom>
            About Raybot Automation
          </Typography>
          <Typography variant="h5" className="about-hero-subtitle" gutterBottom>
            Pioneering Innovation in Automation and Robotics
          </Typography>
        </Container>
      </Box>

      {/* Company History */}
      <Box className="about-history-section">
        <Container maxWidth="lg">
          <Typography variant="h4" className="about-section-title" gutterBottom>
            Our Journey
          </Typography>
          <Typography variant="body1" className="about-section-content" paragraph>
            Raybot Automation Pvt Ltd. embodies a relentless commitment to innovation in the world of automation and robotics, utilizing AI and ML technologies. Founded by visionary entrepreneur Mr. Sagar Patil, our journey commenced with remarkable achievements in renowned events such as IIT Bombay Techfest, IIT Kharagpur, IIT Indore, NIT Calicut, and other prestigious Indian robotics competitions. This is just the beginning, followed by numerous victories across almost every state and prominent city of India. The journey took a significant upward turn when accomplishments from international competitions were added to the list. These early victories not only brought us recognition but also garnered admiration from students, educational institutions, and industry leaders alike.
          </Typography>
        </Container>
      </Box>
      <Box className="about-mvg-section">
        <Container maxWidth="lg">
          <Grid container spacing={4}>
            {/* Mission */}
            <Grid item xs={12} sm={6} md={4}>
              <Paper elevation={3} className="mvg-card">
                <img src={feature7} alt="Mission Logo" className="mvg-icon" />
                <Typography variant="h5" className="mvg-title" gutterBottom>
                  OUR MISSION
                </Typography>
                <Typography variant="body1" className="mvg-content">
                  Our mission is to make robotics accessible to all, inspiring the next generation of engineers, inventors, and problem-solvers. We aim to bring the world to the era of robotics. Whether you're a curious beginner or a seasoned expert, we have something for everyone.
                </Typography>
              </Paper>
            </Grid>

            {/* Vision */}
            <Grid item xs={12} sm={6} md={4}>
              <Paper elevation={3} className="mvg-card">
                <img src={feature8} alt="Vision Logo" className="mvg-icon" />
                <Typography variant="h5" className="mvg-title" gutterBottom>
                  VISION
                </Typography>
                <Typography variant="body1" className="mvg-content">
                  At Raybot Automation, we envision pioneering advances in automation, robotics, AI, and ML that revolutionize industries, enhance sustainability, and elevate lives worldwide. We are dedicated to leading the way in innovation, fostering excellence, and creating a brighter, more automated future.
                </Typography>
              </Paper>
            </Grid>

            {/* Goal */}
            <Grid item xs={12} sm={6} md={4}>
              <Paper elevation={3} className="mvg-card">
                <img src={feature9} alt="Goal Logo" className="mvg-icon" />
                <Typography variant="h5" className="mvg-title" gutterBottom>
                  GOAL
                </Typography>
                <Typography variant="body1" className="mvg-content">
                  To become the leading provider of comprehensive robotics education solutions, empowering students with practical skills and fostering innovation in STEM fields through hands-on learning experiences, cutting-edge technology, and strategic partnerships.
                </Typography>
              </Paper>
            </Grid>
          </Grid>
        </Container>
      </Box>


      {/* Achievements Section */}
      <Box className="about-achievements-section">
        <Container maxWidth="lg">
          <Typography variant="h4" className="about-section-title" gutterBottom>
            Our Achievements
          </Typography>
          <Grid container spacing={4}>
            {/* Achievement 1 */}
            <Grid item xs={12} sm={6} md={3}>
              <Paper elevation={3} className="achievement-card">
                <Typography variant="h5" className="achievement-title" gutterBottom>
                  IIT Excellence Achiever
                </Typography>
                <Typography variant="body1" className="achievement-content">
                  "Pushing the frontiers of innovation and brilliance."
                </Typography>
              </Paper>
            </Grid>

            {/* Achievement 2 */}
            <Grid item xs={12} sm={6} md={3}>
              <Paper elevation={3} className="achievement-card">
                <Typography variant="h5" className="achievement-title" gutterBottom>
                  Global & National Champions
                </Typography>
                <Typography variant="body1" className="achievement-content">
                  "Leading with a legacy of triumph across borders."
                </Typography>
              </Paper>
            </Grid>

            {/* Achievement 3 */}
            <Grid item xs={12} sm={6} md={3}>
              <Paper elevation={3} className="achievement-card">
                <Typography variant="h5" className="achievement-title" gutterBottom>
                  State Champion Behemoth
                </Typography>
                <Typography variant="body1" className="achievement-content">
                  "Rising above with regional dominance."
                </Typography>
              </Paper>
            </Grid>

            {/* Achievement 4 */}
            <Grid item xs={12} sm={6} md={3}>
              <Paper elevation={3} className="achievement-card">
                <Typography variant="h5" className="achievement-title" gutterBottom>
                  Campus Champions
                </Typography>
                <Typography variant="body1" className="achievement-content">
                  "Exemplifying leadership at the academic helm."
                </Typography>
              </Paper>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Container className="map">
        <Box className="map-section">
          <img
            src={featuredmap} // Make sure to place your image in the correct folder
            alt="Map of India"
            className="india-map"
          />
        </Box>
      </Container>



      
      {/* Call to Action */}
      <Box className="about-cta-section">
        <Container maxWidth="md">
          <Typography className="about-cta-title" gutterBottom>
            Join Us in Shaping the Future of Robotics
          </Typography>
          <Typography variant="body1" className="about-cta-description" gutterBottom>
            Whether you're interested in our services, looking to collaborate, or eager to be part of our dynamic team, we'd love to hear from you.
          </Typography>
          <Button
            component={Link}
            to="/contact"
            variant="contained"
            className="about-cta-button"
          >
            Contact Us
          </Button>
        </Container>
      </Box>
    </Box>
  );
}

export default About;
