// src/components/Footer.js
import React from 'react';
import { Box, Typography, Link as MuiLink, IconButton, Grid, Container } from '@mui/material';
import { Link } from 'react-router-dom';
import { LinkedIn, Instagram, YouTube } from '@mui/icons-material';
import './Footer.css'; // Import component-specific styles

import feature1 from '../assets/images/logo.png';

function Footer() {
  return (
    <Box component="footer" className="footer-container">
      <Container maxWidth="lg">
        <Grid container spacing={4} className="footer-grid">
          {/* Navigational Links */}
          <Grid item xs={12} sm={4}>
          <Box className="footer-logo">
              <img src= {feature1} alt="Company Logo" style={{ width: '150px', height: '150' }} />
            </Box>
            <Box className="footer-links">
              <MuiLink component={Link} to="/" className="footer-link">
                Home
              </MuiLink>
              <MuiLink component={Link} to="/about" className="footer-link">
                About Us
              </MuiLink>
              <MuiLink component={Link} to="/services" className="footer-link">
                Services
              </MuiLink>
              <MuiLink component={Link} to="/gallery" className="footer-link">
                Gallery
              </MuiLink>
              <MuiLink component={Link} to="/blog" className="footer-link">
                Blog
              </MuiLink>
              <MuiLink component={Link} to="/contact" className="footer-link">
                Contact Us
              </MuiLink>
            </Box>
          </Grid>

          {/* Social Media Links */}
          <Grid item xs={12} sm={4}>
            <Typography variant="h6" className="footer-title" gutterBottom>
              Follow Us
            </Typography>
            <Box className="footer-socials">
              <IconButton
                component="a"
                href="https://www.linkedin.com/company/raybot-automation/"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="LinkedIn"
                className="social-icon"
              >
                <LinkedIn />
              </IconButton>

              <IconButton
                component="a"
                href="https://www.instagram.com/raybot_labs/"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Instagram"
                className="social-icon"
              >
                <Instagram />
              </IconButton>

              <IconButton
                component="a"
                href="https://www.youtube.com/@RaybotLabs"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="YouTube"
                className="social-icon"
              >
                <YouTube />
              </IconButton>
            </Box>
          </Grid>


          {/* Company Information */}
          <Grid item xs={12} sm={4}>
            <Typography variant="h6" className="footer-title" gutterBottom>
              Contact Us
            </Typography>
            <Box className="footer-contact">
              <Typography variant="body1" className="contact-item">
                <strong>Address:</strong> Flat No 4, Rahul Appartment, Gangapur Rd, Old Pandit Colony, Police Staff Colony, Nashik, Maharashtra 422002
              </Typography>
              <Typography variant="body1" className="contact-item">
                <strong>Email:</strong> <MuiLink href="mailto:info@raybotautomation.com" className="footer-link">teamraybotlab@gmail.com</MuiLink>
              </Typography>
              <Typography variant="body1" className="contact-item">
                <strong>Phone:</strong> <MuiLink href="tel:+1234567890" className="footer-link">(+91) 7796403251,
                  7798403251 </MuiLink>
              </Typography>
            </Box>
          </Grid>
        </Grid>

        {/* Footer Bottom */}
        <Box className="footer-bottom">
          <Typography variant="body2">
            &copy; {new Date().getFullYear()} Raybot Automation Pvt Ltd. All rights reserved.
          </Typography>
        </Box>
      </Container>
    </Box>
  );
}

export default Footer;
