import React from 'react';
import '../components/Careers.css';
import { FaArrowRight, FaCheckCircle } from 'react-icons/fa';

const Careers = () => {
  return (
    <div className="careers-page">
      {/* Hero Section */}
      <section className="careers-hero">
        <h1>Join Our Team</h1>
        <p>Build your future with us! Explore exciting career opportunities and become part of our innovative team.</p>
      </section>

      {/* Why Join Us Section */}
      <section className="why-join-us">
        <h2>Why Join Us!</h2>
        <div className="benefits">
          <div className="benefit-item">
            <FaCheckCircle />
            <h3>Competitive Salary</h3>
            <p>We offer industry-leading salaries and benefits.</p>
          </div>
          <div className="benefit-item">
            <FaCheckCircle />
            <h3>Remote Flexibility</h3>
            <p>Work from anywhere or join us at our modern office.</p>
          </div>
        </div>
      </section>

      {/* Job Listings Section */}
      <section id="job-listings" className="job-listings">
        <h2>Current Openings</h2>
        <div className="job-listing">
          <h3>Robotics Trainer</h3>
          <p>Location: Remote | Full-Time</p>
        </div>
        <div className="job-listing">
          <h3>Bussiness Dvelopment Associate</h3>
          <p>Location: Remote | Full-Time</p>
        </div>
        {/* Add more job listings as needed */}

        {/* Common Application Information */}
        <div className="application-info">
          <p>
            To apply, please send your resume to <a href="mailto:hr@raybotlabs.com">hr@raybotlabs.com</a>
          </p>
        </div>
      </section>

     {/* FAQ Section */}
<section className="faq-section">
  <h2>Frequently Asked Questions</h2>

  <div className="faq-item">
    <h3>What benefits do you offer?</h3>
    <p>We provide comprehensive healthcare, paid time off, and retirement plans.</p>
  </div>

  <div className="faq-item">
    <h3>How do I apply for a position?</h3>
    <p>You can apply by visiting our Careers page, selecting the job you're interested in, and submitting your application online.</p>
  </div>

  <div className="faq-item">
    <h3>Do you offer remote work opportunities?</h3>
    <p>Yes, we offer flexible work arrangements, including remote and hybrid options, depending on the role and team requirements.</p>
  </div>

  <div className="faq-item">
    <h3>What is the company culture like?</h3>
    <p>Our company culture is inclusive, collaborative, and focused on continuous learning and innovation. We prioritize employee well-being and teamwork.</p>
  </div>

  <div className="faq-item">
    <h3>Are there growth opportunities?</h3>
    <p>Yes, we offer career development programs, mentorship, and regular performance reviews to help employees advance in their careers.</p>
  </div>

  <div className="faq-item">
    <h3>How does the hiring process work?</h3>
    <p>The hiring process typically includes an application review, initial screening, one or more interviews, and reference checks.</p>
  </div>

  <div className="faq-item">
    <h3>What is your approach to diversity and inclusion?</h3>
    <p>We are committed to fostering a diverse and inclusive workplace by promoting equity in hiring, advancement, and all employee experiences.</p>
  </div>
</section>

    </div>
  );
};

export default Careers;
