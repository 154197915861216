// src/App.js
import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Header from './components/Header';
import Footer from './components/Footer';
import AnimatedRoutes from './components/AnimatedRoutes';
import BackToTop from './components/BackToTop';

function App() {
  // Define a black and white theme using MUI's createTheme
  const bwTheme = createTheme({
    palette: {
      mode: 'dark', // Dark mode for black background
      primary: {
        main: '#ffffff', // White primary color
      },
      secondary: {
        main: '#ffffff', // White secondary color
      },
      background: {
        default: '#000000', // Black background
        paper: '#000000', // Black paper background
      },
      text: {
        primary: '#ffffff', // White text
        secondary: '#ffffff', // White secondary text
      },
    },
    typography: {
      fontFamily: ['Arial', 'sans-serif'].join(','),
      h1: {
        
      },
      h2: {
        
      },
      h3: {
        color: '#ffffff',
      },
      h4:{
        color:'#000000',
      }
      // Add more typography settings if needed
    },
  });

  return (
    <ThemeProvider theme={bwTheme}>
      <CssBaseline />
      <Router>
      <BackToTop />
        <Header />
        <AnimatedRoutes />
        <Footer />
        <BackToTop />
      </Router>
    </ThemeProvider>
  );
}

export default App;
