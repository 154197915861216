import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

// This component will scroll to the top of the page on route change
const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top
  }, [pathname]); // This effect runs whenever the pathname changes

  return null; // This component doesn't render anything visible
};

export default ScrollToTop;
