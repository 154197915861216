import React, { useState } from 'react';
import {
  AppBar,
  Toolbar,
  Box,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
} from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import { Menu as MenuIcon } from '@mui/icons-material';
import { styled } from '@mui/system';
import logo from '../assets/images/logo.png'; // Ensure this path is correct
import './Header.css'; // Import component-specific styles

// Styled Link component with active class
const StyledLink = styled(Link)(({ isActive }) => ({
  color: isActive ? '#ffffff' : '#cccccc', // White for active, light grey for inactive
  margin: '0 15px',
  textDecoration: 'none',
  fontSize: '1rem',
  '&:hover': {
    textDecoration: 'underline',
    color: '#ffffff', // White on hover
  },
}));

function Header() {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const location = useLocation(); // Get current location

  const toggleDrawer = (open) => () => {
    setDrawerOpen(open);
  };

  const navLinks = [
    { title: 'Home', path: '/' },
    { title: 'About', path: '/about' },
    { title: 'Services', path: '/services' },
    { title: 'Gallery', path: '/gallery' },
    { title: 'Blog', path: '/blog' },
    { title: 'Contact', path: '/contact' },
    { title: 'Careers', path: '/careers' },
  ];

  const list = (
    <Box
      className="drawer-list"
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <List>
        {navLinks.map((item, index) => (
          <ListItem
            button
            key={index}
            component={Link}
            to={item.path}
            className={
              location.pathname === item.path ? 'drawer-active-link' : 'drawer-link'
            }
          >
            <ListItemText primary={item.title} />
          </ListItem>
        ))}
      </List>
    </Box>
  );

  return (
    <>
      <AppBar position="sticky" className="appbar">
        <Toolbar className="toolbar">
          {/* Logo */}
          <IconButton
            component={Link}
            to="/"
            edge="start"
            aria-label="Robotics World Home"
            className="logo-button"
          >
            <img src={logo} alt="RayBot Automation" className="logo" />
          </IconButton>

          {/* Desktop Navigation Links */}
          <Box className="nav-links">
            {navLinks.map((item, index) => (
              <StyledLink
                key={index}
                to={item.path}
                isActive={location.pathname === item.path ? 1 : 0} // Pass isActive prop
              >
                {item.title}
              </StyledLink>
            ))}
          </Box>

          {/* Hamburger Menu for Mobile */}
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="end"
            onClick={toggleDrawer(true)}
            className="hamburger-menu"
          >
            <MenuIcon />
          </IconButton>

          {/* Drawer Component */}
          <Drawer anchor="right" open={drawerOpen} onClose={toggleDrawer(false)}>
            {list}
          </Drawer>
        </Toolbar>
      </AppBar>
    </>
  );
}

export default Header;
