import React, { useState } from 'react';
import './Blog.css';

import feature1 from '../assets/images/Blog/Artificial Intelligence (AI).jpg';
import feature2 from '../assets/images/Blog/Industry 4.0.jpg';
import feature3 from '../assets/images/Blog/space exploration.jpg';

const Blog = () => {
  const [modalContent, setModalContent] = useState(null);
  const [isModalOpen, setModalOpen] = useState(false);

  const blogs = [
    {
      title: 'Artificial Intelligence',
      image: feature1,
      content: 'Artificial Intelligence (AI) enables machines to perform tasks that typically require human intelligence, such as learning, reasoning, and understanding language. In recent years, AI has become integral across various sectors, including healthcare, finance, and education. In healthcare, AI assists in diagnosing diseases and developing personalized treatment plans. Financial institutions utilize AI for fraud detection and risk assessment. In education, AI-powered tools offer personalized learning experiences, catering to individual student needs. As AI continues to evolve, it promises to enhance efficiency and unlock new possibilities in numerous fields.'
    },
    {
      title: 'Industry 4.0',
      image: feature2,
      content: 'Industry 4.0 refers to the fourth industrial revolution, driven by advancements in automation, artificial intelligence (AI), and the Internet of Things (IoT). It involves the integration of smart technologies into manufacturing and industrial processes, enabling greater efficiency, customization, and data-driven decision-making. Factories equipped with sensors, robots, and AI systems can monitor operations in real-time, optimizing production, reducing waste, and predicting maintenance needs. Industry 4.0 also fosters collaboration between machines and humans, leading to enhanced productivity and safety. As industries embrace digital transformation, this revolution is reshaping sectors like manufacturing, logistics, and supply chains, ultimately paving the way for smart factories and a more connected, efficient future.'
    },
    {
      title: 'Space exploration',
      image: feature3,
      content: 'Robotics plays a pivotal role in space exploration, enabling missions that are too dangerous, distant, or difficult for humans. Robotic systems, such as rovers, landers, and robotic arms, are essential for exploring planets like Mars, conducting scientific experiments, and collecting data from harsh environments. These machines can operate autonomously or be remotely controlled from Earth, performing tasks like drilling into rock surfaces, analyzing soil samples, and navigating rugged terrains. The Mars rovers, such as Curiosity and Perseverance, are prime examples of how robotics has advanced space exploration. Robotics also aids in satellite deployment, space station maintenance, and potential asteroid mining. As technology evolves, robotics will continue to push the boundaries of space exploration, making new discoveries and paving the way for human space travel.'
    },
    // Add more blog posts as needed
  ];

  const openModal = (content) => {
    setModalContent(content);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
    setModalContent(null);
  };

  return (
    <div className="blog-container">
      {/* Header Section */}
      <header className="blog-header">
        <h1 className="blog-title">Our Blog</h1>
        <p className="blog-subtitle">Insights, Updates, and Stories from Raybot Automation</p>
      </header>

      {/* Blog Cards Section */}
      <div className="blog-cards">
        {blogs.map((blog, index) => (
          <div className="blog-card" key={index}>
            <img src={blog.image} alt={blog.title} className="blog-image" />
            <h2 className="blog-card-title">{blog.title}</h2>
            <button className="read-more-button" onClick={() => openModal(blog.content)}>
              Read More
            </button>
          </div>
        ))}
      </div>

      {/* Modal for Read More */}
      {isModalOpen && (
        <div className="modal">
          <div className="modal-content">
            <button className="close-button" onClick={closeModal}>&times;</button>
            <h2>Blog Details</h2>
            <p>{modalContent}</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default Blog;
