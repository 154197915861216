import React, { useState } from 'react';
import './Gallery.css';

import feature1 from '../assets/images/Gallery/Achievement/1.png';
import feature2 from '../assets/images/Gallery/Achievement/2.JPG';
import feature3 from '../assets/images/Gallery/Achievement/3.jpg';
import feature4 from '../assets/images/Gallery/Achievement/4.jpg';
import feature5 from '../assets/images/Gallery/Achievement/5.jpg';
import feature6 from '../assets/images/Gallery/Achievement/6.jpg';
import feature7 from '../assets/images/Gallery/Achievement/7.jpg';

import feature8 from '../assets/images/Gallery/Exhibition/1.JPG';
import feature9 from '../assets/images/Gallery/Exhibition/2.jpg';
import feature10 from '../assets/images/Gallery/Exhibition/3.jpg';
import feature11 from '../assets/images/Gallery/Exhibition/4.jpg';
import feature12 from '../assets/images/Gallery/Exhibition/5.jpg';

import feature13 from '../assets/images/Gallery/Lab/1.png';
import feature14 from '../assets/images/Gallery/Lab/2.jpg';
import feature15 from '../assets/images/Gallery/Lab/3.jpg';
import feature16 from '../assets/images/Gallery/Lab/4.jpg';
import feature17 from '../assets/images/Gallery/Lab/5.jpg';
import feature18 from '../assets/images/Gallery/Lab/6.jpg';
import feature19 from '../assets/images/Gallery/Lab/7.jpg';
import feature20 from '../assets/images/Gallery/Lab/8.jpg';
import feature21 from '../assets/images/Gallery/Lab/9.JPG';
import feature22 from '../assets/images/Gallery/Lab/10.JPG';

import feature23 from '../assets/images/Gallery/Ray Robotics/1.jpg';
import feature24 from '../assets/images/Gallery/Ray Robotics/2.JPG';
import feature25 from '../assets/images/Gallery/Ray Robotics/3.jpg';
import feature26 from '../assets/images/Gallery/Ray Robotics/4.JPG';
import feature27 from '../assets/images/Gallery/Ray Robotics/5.JPG';
import feature28 from '../assets/images/Gallery/Ray Robotics/6.JPG';
import feature29 from '../assets/images/Gallery/Ray Robotics/7.jpg';

import feature30 from '../assets/images/Gallery/School/1.jpg';
import feature31 from '../assets/images/Gallery/School/2.jpg';
import feature32 from '../assets/images/Gallery/School/3.jpg';
import feature33 from '../assets/images/Gallery/School/4.jpg';
import feature34 from '../assets/images/Gallery/School/5.jpg';
import feature35 from '../assets/images/Gallery/School/6.jpg';

import feature36 from '../assets/images/Gallery/VR/1.jpg';
import feature37 from '../assets/images/Gallery/VR/2.jpg';
import feature38 from '../assets/images/Gallery/VR/3.jpg';
import feature39 from '../assets/images/Gallery/VR/4.jpg';
import feature40 from '../assets/images/Gallery/VR/5.jpg';

import feature41 from '../assets/images/Gallery/Workshops/1.jpg';
import feature42 from '../assets/images/Gallery/Workshops/2.jpg';
import feature43 from '../assets/images/Gallery/Workshops/3.jpg';
import feature44 from '../assets/images/Gallery/Workshops/4.jpg';
import feature45 from '../assets/images/Gallery/Workshops/5.jpg';

import feature46 from '../assets/images/Gallery/Robots/1.JPG';
import feature47 from '../assets/images/Gallery/Robots/2.jpg';

import feature48 from '../assets/images/Gallery/News/1.jpg';
import feature49 from '../assets/images/Gallery/News/2.jpg';
import feature50 from '../assets/images/Gallery/News/3.jpg';
import feature51 from '../assets/images/Gallery/News/4.jpg';
import feature52 from '../assets/images/Gallery/News/5.jpg';
import feature53 from '../assets/images/Gallery/News/6.jpg';
import feature54 from '../assets/images/Gallery/News/7.jpg';
import feature55 from '../assets/images/Gallery/News/8.jpg';
import feature56 from '../assets/images/Gallery/News/9.jpg';
import feature57 from '../assets/images/Gallery/News/10.jpg';
import feature58 from '../assets/images/Gallery/News/11.jpg';
import feature59 from '../assets/images/Gallery/News/12.png';
import feature60 from '../assets/images/Gallery/News/13.png';


const images = [
  { id: 1, src: feature1, category: 'achievement' },
  { id: 2, src: feature2, category: 'achievement' },
  { id: 3, src: feature3, category: 'achievement' },
  { id: 4, src: feature4, category: 'achievement' },
  { id: 5, src: feature5, category: 'achievement' },
  { id: 6, src: feature6, category: 'achievement' },
  { id: 7, src: feature7, category: 'achievement' },

  { id: 8, src: feature8, category: 'exhibition' },
  { id: 9, src: feature9, category: 'exhibition' },
  { id: 10, src: feature10, category: 'exhibition' },
  { id: 11, src: feature11, category: 'exhibition' },
  { id: 12, src: feature12, category: 'exhibition' },

  { id: 13, src: feature13, category: 'lab' },
  { id: 14, src: feature14, category: 'lab' },
  { id: 15, src: feature15, category: 'lab' },
  { id: 16, src: feature16, category: 'lab' },
  { id: 17, src: feature17, category: 'lab' },
  { id: 18, src: feature18, category: 'lab' },
  { id: 19, src: feature19, category: 'lab' },
  { id: 20, src: feature20, category: 'lab' },
  { id: 21, src: feature21, category: 'lab' },
  { id: 22, src: feature22, category: 'lab' },

  { id: 23, src: feature23, category: 'ray robotics' },
  { id: 24, src: feature24, category: 'ray robotics' },
  { id: 25, src: feature25, category: 'ray robotics' },
  { id: 26, src: feature26, category: 'ray robotics' },
  { id: 27, src: feature27, category: 'ray robotics' },
  { id: 28, src: feature28, category: 'ray robotics' },
  { id: 29, src: feature29, category: 'ray robotics' },

  { id: 30, src: feature30, category: 'school' },
  { id: 31, src: feature31, category: 'school' },
  { id: 32, src: feature32, category: 'school' },
  { id: 33, src: feature33, category: 'school' },
  { id: 34, src: feature34, category: 'school' },
  { id: 35, src: feature35, category: 'school' },

  { id: 36, src: feature36, category: 'VR' },
  { id: 37, src: feature37, category: 'VR' },
  { id: 38, src: feature38, category: 'VR' },
  { id: 39, src: feature39, category: 'VR' },
  { id: 40, src: feature40, category: 'VR' },

  { id: 41, src: feature41, category: 'workshops' },
  { id: 42, src: feature42, category: 'workshops' },
  { id: 43, src: feature43, category: 'workshops' },
  { id: 44, src: feature44, category: 'workshops' },
  { id: 45, src: feature45, category: 'workshops' },

  { id: 46, src: feature46, category: 'Robots' },
  { id: 47, src: feature47, category: 'Robots' },

  { id: 48, src: feature48, category: 'news' },
  { id: 49, src: feature49, category: 'news' },
  { id: 50, src: feature50, category: 'news' },
  { id: 51, src: feature51, category: 'news' },
  { id: 52, src: feature52, category: 'news' },
  { id: 53, src: feature53, category: 'news' },
  { id: 54, src: feature54, category: 'news' },
  { id: 55, src: feature55, category: 'news' },
  { id: 56, src: feature56, category: 'news' },
  { id: 57, src: feature57, category: 'news' },
  { id: 58, src: feature58, category: 'news' },
  { id: 59, src: feature59, category: 'news' },
  { id: 60, src: feature60, category: 'news' },
  


  // Add more images here
];

const categories = [
  'all',
  'achievement',
  'news',
  'exhibition',
  'lab',
  'ray robotics',
  'school',
  'VR',
  'workshops',
  'Robots',
];

const Gallery = () => {
  const [selectedCategory, setSelectedCategory] = useState('all');

  const filterImages = () => {
    if (selectedCategory === 'all') {
      return images;
    }
    return images.filter(image => image.category === selectedCategory);
  };

  return (
    <div className="gallery-container">
      <h1 className="gallery-title">A visual journey through our work!!</h1>

      {/* Filter buttons */}
      <div className="gallery-filters">
        {categories.map(category => (
          <button
            key={category}
            className={`filter-btn ${selectedCategory === category ? 'active' : ''}`}
            onClick={() => setSelectedCategory(category)}
          >
            {category.charAt(0).toUpperCase() + category.slice(1)}
          </button>
        ))}
      </div>

      {/* Gallery Grid */}
      <div className="gallery-grid">
        {filterImages().map(image => (
          <div key={image.id} className="gallery-item">
            <img src={image.src} alt={image.category} className="gallery-img" />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Gallery;
