// src/components/Home.js
import React from 'react';
import { Box, Typography, Button, Grid, Container, Avatar, Paper } from '@mui/material';
import { Link } from 'react-router-dom';
import Slider from "react-slick"; // Importing the slider component
import './Home.css'; // Import component-specific styles
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import feature11 from '../assets/images/Expertise/Pratical Learning.jpg';
import feature22 from '../assets/images/Expertise/Hands on experience.jpg';
import feature33 from '../assets/images/Expertise/World wide.jpg';

import feature1 from '../assets/images/Company/1.webp';
import feature2 from '../assets/images/Company/2.jpg';
import feature3 from '../assets/images/Company/3.png';
import feature4 from '../assets/images/Company/4.jpg';
import feature5 from '../assets/images/Company/5.png';
import feature6 from '../assets/images/Company/6.png';
import feature7 from '../assets/images/Company/7.png';
import feature8 from '../assets/images/Company/8.png';
import feature9 from '../assets/images/Company/9.jpg';
import feature10 from '../assets/images/Company/10.jpg';

import feature12 from '../assets/images/Trusted By/1.png';
import feature13 from '../assets/images/Trusted By/2.png';
import feature14 from '../assets/images/Trusted By/3.jpg';

// Import slider images correctly
const sliderImages = [
  require('../assets/images/slider/1.JPG'),
  require('../assets/images/slider/7.jpg'),
  require('../assets/images/slider/8.jpg'),
  require('../assets/images/slider/2.jpg'),
  require('../assets/images/slider/4.jpg'),
  require('../assets/images/slider/3.jpg'),
];

function Home() {
  // Slider settings
  const settings = {
    dots: true,
    infinite: true,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: false, // Hide arrows if you prefer
    pauseOnHover: true, // Disable pausing on hover
    pauseOnFocus: false, // Disable pausing on focus
    pauseOnDotsHover: false, // Disable pausing on dots hover
  };

  // Logo settings for horizontal scrolling
  const logoSettings = {
    dots: false,
    infinite: true,
    speed: 300,
    slidesToShow: 4, // Show multiple logos at once
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    cssEase: "linear",
  };

  return (
    <Box className="home-container">
      {/* Image Slider Section */}
      <Box className="slider-section">
        <Slider {...settings}>
          {sliderImages.map((image, index) => (
            <div key={index} className="slide">
              <img src={image} alt={`Slide ${index + 1}`} className="slider-image" />

            </div>
          ))}
        </Slider>
        <Container maxWidth="md" className="slider-text-container">
          <Typography className="hero-title" gutterBottom>
            Welcome to Raybot Automation
          </Typography>
          <Typography variant="h5" className="hero-subtitle" gutterBottom>
            Innovating the Future with Cutting-Edge Robotics Solutions
          </Typography>
          <Button component={Link} to="/services"  className="hero-button">
            Explore Our Services
          </Button>
        </Container>
      </Box>

      {/* Gap between Hero Section and Expertise Section */}
      <Box className="gap" />

      {/* Expertise Section */}
      <Box className="features-section">
        <Container maxWidth="lg">
          <Typography variant="h4" className="features-title" gutterBottom>
            Our Expertise
          </Typography>
          <Grid container spacing={4}>
            {/* Feature 1 */}
            <Grid item xs={12} sm={6} md={4}>
              <Box className="feature-card">
                <Avatar
                  alt="Raybot Automation robotics lab for students"
                  src={feature11}
                  className="feature-icon"
                />
                <Typography variant="h6" className="feature-title">
                  Pratical Learning
                </Typography>
                <Typography variant="body1" className="feature-description">
                  Practical Learning, empowering individuals with hands-on experience.
                </Typography>
              </Box>
            </Grid>
            {/* Feature 2 */}
            <Grid item xs={12} sm={6} md={4}>
              <Box className="feature-card">
                <Avatar
                  alt="Raybot Automation robotics lab for students"
                  src={feature22}
                  className="feature-icon"
                />
                <Typography variant="h6" className="feature-title">
                  Hands On Experience
                </Typography>
                <Typography variant="body1" className="feature-description">
                  Our Expertise: Hands On Experience, providing tailored robotic systems for specific business needs.
                </Typography>
              </Box>
            </Grid>
            {/* Feature 3 */}
            <Grid item xs={12} sm={6} md={4}>
              <Box className="feature-card">
                <Avatar
                  alt="Raybot Automation robotics lab for students"
                  src={feature33}
                  className="feature-icon"
                />
                <Typography variant="h6" className="feature-title">
                  Worldwide competition
                </Typography>
                <Typography variant="body1" className="feature-description">
                  Worldwide Competition, backed by a dedicated support team for seamless integration and operation.
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>


      {/* About Us Preview */}
      <Box className="about-preview-section">
        <Container maxWidth="md">
          <Typography variant="h4" className="about-title" gutterBottom>
            About Raybot Automation
          </Typography>
          <Typography variant="body1" className="about-description" gutterBottom>
            At Raybot Automation, we are committed to advancing automation and robotics. Our mission is to integrate intelligent robotic solutions that drive efficiency, precision, and innovation across industries.
          </Typography>
        </Container>
      </Box>

      {/* Testimonials Slider */}
      <Box className="testimonials-section">
        <Container maxWidth="lg">
          <Typography variant="h4" className="testimonials-title" gutterBottom>
            What Our Clients Say
          </Typography>
          <Grid container spacing={4}>
            {/* Testimonial 1 */}
            <Grid item xs={12} sm={6} md={4}>
              <Paper elevation={3} className="testimonial-card">
                <Box className="testimonial-content">

                  <Typography variant="body1" className="testimonial-feedback">
                    To Sagar and the Raybot Team, thank you for your unwavering support. Sagar, your generosity in teaching my underprivileged students and helping them build robots is deeply appreciated. Your expertise and dedication in robotics have made a lasting impact. We look forward to continuing our partnership.
                  </Typography>
                  <Typography variant="subtitle1" className="testimonial-name">
                    - Rutuja Satpute
                  </Typography>
                </Box>
              </Paper>
            </Grid>
            {/* Testimonial 2 */}
            <Grid item xs={12} sm={6} md={4}>
              <Paper elevation={3} className="testimonial-card">
                <Box className="testimonial-content">

                  <Typography variant="body1" className="testimonial-feedback">
                  We were thoroughly impressed with the robot service by Raybot. The team showed exceptional professionalism, and the robot’s performance exceeded expectations, streamlining processes and improving efficiency. Their support and expertise made the experience seamless. Highly recommended!
                  </Typography>
                  <Typography variant="subtitle1" className="testimonial-name">
                    - T & T Solutions Pvt Ltd
                  </Typography>
                </Box>
              </Paper>
            </Grid>
            {/* Testimonial 3 */}
            <Grid item xs={12} sm={6} md={4}>
              <Paper elevation={3} className="testimonial-card">
                <Box className="testimonial-content">

                  <Typography variant="body1" className="testimonial-feedback">
                  To Raybot Automation Team, we sincerely appreciate the exceptional services you provided to our students at MGES. Your dedication to hands-on training and project-based learning has greatly benefited our students. Your passion for automation and robotics has inspired them to explore STEM fields and develop essential skills for the future. Thank you for your commitment and collaboration.
                  </Typography>
                  <Typography variant="subtitle1" className="testimonial-name">
                    -Mahatma Gandhi English School(CBSE), Ujani Tq Ausa Dist Latur.
                  </Typography>
                </Box>
              </Paper>
            </Grid>

            {/* Add more testimonials if needed */}
          </Grid>
        </Container>
      </Box>
      {/* Company Logo Scrolling Section */}
      <Box className="company-logos-section">
        <Container>
          <Typography variant="h4" className="logos-title" gutterBottom>
            Our Partners
          </Typography>
          <Slider {...logoSettings} className="logos-slider">
            {/* Add more company logos */}
            <div className="logo-slide">
              <div className="logo-shape">
                <img src={feature1} alt="Company 1" className="company-logo" />
              </div>
            </div>
            <div className="logo-slide">
              <div className="logo-shape">
                <img src={feature2} alt="Company 2" className="company-logo" />
              </div>
            </div>
            <div className="logo-slide">
              <div className="logo-shape">
                <img src={feature3} alt="Company 3" className="company-logo" />
              </div>
            </div>
            <div className="logo-slide">
              <div className="logo-shape">
                <img src={feature4} alt="Company 3" className="company-logo" />
              </div>
            </div>
            <div className="logo-slide">
              <div className="logo-shape">
                <img src={feature5} alt="Company 3" className="company-logo" />
              </div>
            </div>
            <div className="logo-slide">
              <div className="logo-shape">
                <img src={feature6} alt="Company 3" className="company-logo" />
              </div>
            </div>
            <div className="logo-slide">
              <div className="logo-shape">
                <img src={feature7} alt="Company 3" className="company-logo" />
              </div>
            </div>
            <div className="logo-slide">
              <div className="logo-shape">
                <img src={feature8} alt="Company 3" className="company-logo" />
              </div>
            </div>
            <div className="logo-slide">
              <div className="logo-shape">
                <img src={feature9} alt="Company 3" className="company-logo" />
              </div>
            </div>
            <div className="logo-slide">
              <div className="logo-shape">
                <img src={feature10} alt="Company 3" className="company-logo" />
              </div>
            </div>
            {/* Repeat for other logos */}
          </Slider>
        </Container>
      </Box>

       {/* Trusted By Section */}
       <section className="trusted-by">
        <h2 className="trusted-heading">Trusted By</h2>
        <div className="trusted-logos">
          <img src={feature12} alt="Logo 1" className="Tlogo"/>
          <img src={feature13} alt="Logo 2" className="Tlogo"/>
          <img src={feature14} alt="Logo 3" className="Tlogo"/>
        </div>
      </section>


    </Box>
  );
}

export default Home;
