import React from 'react';
import { Box, Typography, Grid } from '@mui/material';
import './Services.css';

import image1 from '../assets/images/Services/Robotics & AI Labs for ICSE Schools.jpg';
import image2 from '../assets/images/Services/Atal Tinkering Lab.webp';
import image3 from '../assets/images/Services/Lazar cutting.jpg';
import image4 from '../assets/images/Services/STEAM Innovation Lab.webp';
import image5 from '../assets/images/Services/AR VR.jpg';
import image6 from '../assets/images/Services/PCB.jpg';
import image7 from '../assets/images/Services/3D RED AND BLACK.webp';

const servicesData = [
  {
    title: "Robotics & AI Labs for ICSE Schools",
    image: image1,
    description: "Enhancing student learning with hands-on robotics experiences."
  },
  {
    title: "Atal Tinkering Lab",
    image: image2,
    description: "Fostering innovation and creativity in young minds."
  },
  {
    title: "Laser Cutting",
    image: image3,
    description: "Precision cutting services for various materials."
  },
  {
    title: "STEM Innovation Lab",
    image: image4,
    description: "Promoting STEM education through interactive labs."
  },
  {
    title: "AR & VR Lab",
    image: image5,
    description: "Immersive learning experiences through augmented and virtual reality."
  },
  {
    title: "PCB Design & Manufacturing",
    image: image6,
    description: "Custom PCB solutions for diverse applications."
  },
  {
    title: "3D Printing",
    image: image7,
    description: "Innovative 3D printing services for prototyping and production."
  },
];

function Services() {
  return (
    <Box className="services-container">
      <Box className="services-header">
        <Typography variant="h2" className="services-title">
          Services We Provide
        </Typography>
        <Typography variant="subtitle1" className="services-quote">
          "Innovating Today for a Smarter Tomorrow"
        </Typography>
      </Box>

      <Box className="services-grid">
        {servicesData.map((service, index) => (
          <Box key={index} className="service-card">
            <img src={service.image} alt={service.title} className="service-image" />
            <Typography variant="h4" className="service-title">{service.title}</Typography>
            <Typography className="service-description">{service.description}</Typography>
          </Box>
        ))}
      </Box>
    </Box>
  );
}

export default Services;
